<template>
  <div>
    <v-alert v-if="!manifest.started_at && manifest.id" class="mt-2" type="warning" dense text outlined>
      <h2>Please leave by {{ $moment(manifest.estimated_started_at).format('h:mma') }}</h2>
      <p><strong>Important</strong> Please ensure you start the manifest before setting off</p>
      <v-btn depressed color="orange" dark small :loading="loading" @click="startManifest">Start Manifest</v-btn>
    </v-alert>
    <DataTable
      ref="dataTable"
      item-key="booking_bike_manifest.id"
      :cols="cols"
      :path="`manifests/${manifestId}/bikes`"
      :request-params="{ with: ['booking.driverNotes.createdBy', 'asset'] }"
      :show-select="false"
    >
      <template v-slot:item.ref="{ item }">
        <v-list-item style="padding-left: 0px;">
          <v-list-item-content three-line>
            <v-list-item-title class="font-weight-medium text-body-2">
              <router-link :to="{ name: 'bookings.show', params: { bookingId: item.booking_id } }">
                {{ item.ref }}
              </router-link>
              <ManifestJobNotesButton v-if="item.booking.driver_notes.length > 0" class="mt-2" :job="item" />
            </v-list-item-title>

            <template v-if="['JOB_TYPE_HUB_AND_SPOKE', 'JOB_TYPE_HUB_AND_SPOKE_COMPLAINT'].includes(item.job_type)">
              <v-list-item-subtitle class="text-caption mt-2">
                <strong>HUB & SPOKE - GO TO STORE - {{ item.booking.po_number ? item.booking.po_number.substring(2) : '' }}</strong>
              </v-list-item-subtitle>
            <v-list-item-subtitle class="text-caption">
              <v-icon x-small>mdi-account</v-icon>
              {{ item.booking.customer_name }}
            </v-list-item-subtitle>
            </template>
            <v-list-item-subtitle v-else class="text-caption mt-2">
              <v-icon x-small>mdi-account</v-icon>
              {{ item.booking.customer_name }}
            </v-list-item-subtitle>

            <v-list-item-subtitle class="text-caption">
              <v-icon x-small>mdi-bicycle</v-icon>
              {{ item.manafacturer ? `${item.manafacturer} -` : item.manafacturer }} {{ item.colour }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text-caption">
              <v-icon x-small>mdi-bicycle</v-icon>
              {{ item.type_label }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.asset" class="text-caption">
              <v-icon x-small>mdi-bicycle</v-icon>
              {{ item.asset.number }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="['JOB_TYPE_HUB_AND_SPOKE', 'JOB_TYPE_HUB_AND_SPOKE_COMPLAINT'].includes(item.job_type)" class="text-caption">
              <v-icon x-small>mdi-phone</v-icon>
              Do Not Call Customer
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else class="text-caption">
              <v-icon x-small>mdi-phone</v-icon>
              <a :href="`circleloop:${item.booking.customer_mobile}`">
                {{ item.booking.customer_mobile }}
              </a>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.booking.customer_phone && (item.booking.customer_phone !== item.booking.customer_mobile)" class="text-caption">
              <v-icon x-small>mdi-phone</v-icon>
              <a :href="`circleloop:${item.booking.customer_phone}`">
                {{ item.booking.customer_phone }}                
              </a>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.location" class="text-caption mt-2">
              <v-icon x-small>mdi-hook</v-icon>
              <strong>{{ item.location_prefix_label }}</strong> {{ item.location }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text-caption mt-2">
              <v-icon x-small>mdi-map-marker</v-icon>
              <v-dialog
                scrollable
                max-width="300px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" v-if="item.booking_bike_manifest.type === 'TYPE_RETURN'" class="font-weight-bold text-decoration-underline">
                    {{ item.booking.return_postcode }}
                  </span>
                  <span v-bind="attrs" v-on="on" v-else class="font-weight-bold text-decoration-underline">
                    {{ item.booking.collection_postcode }}
                  </span>
                </template>
                <v-card>
                  <v-card-title>{{ item.booking_bike_manifest.type === 'TYPE_RETURN' ? 'Return' : 'Collection' }} Address</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-alert class="mt-2" dense text outlined>
                      <p>{{ item.booking_bike_manifest.type === 'TYPE_RETURN' ? item.booking.return_address : item.booking.collection_address }}</p>
                    </v-alert>
                    <v-btn
                      color="green"
                      dark
                      depressed
                      block
                      target="_blank"
                      :href="`https://waze.com/ul?navigate=yes${item.booking_bike_manifest.type === 'TYPE_RETURN' ? `&q=${item.booking.return_address}&ll=${item.booking.return_latitude},${item.booking.return_longitude}` : `&q=${item.booking.collection_address}&ll=${item.booking.collection_latitude},${item.booking.collection_longitude}` }`"
                    >
                      Navigate
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.booking_bike_manifest.time_window_label" class="text-caption">
              <v-icon x-small>mdi-timer-outline</v-icon>
              {{ item.booking_bike_manifest.time_window_label }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.booking_bike_manifest.estimated_cr_at" class="text-caption">
              <v-icon x-small>mdi-timer-sand</v-icon>
              <span class="font-weight-medium">{{ $moment(item.booking_bike_manifest.estimated_cr_at).format('h:mma') }}</span>
              <span v-if="item.booking_bike_manifest.cr_at" class="ml-1 font-weight-medium green--text">({{ $moment(item.booking_bike_manifest.cr_at).format('h:mma') }})</span>
              <v-icon v-if="item.booking_bike_manifest.cr_at" color="green" x-small>mdi-check-circle</v-icon>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text-caption mt-2">
              <v-chip
                :color="(item.booking_bike_manifest.type === 'TYPE_RETURN') ? 'red' : 'green'"
                dark
                x-small
              >
                {{ item.booking_bike_manifest.type_label }}
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:item.actions="{ item: bike }">
        <ManifestDriverJobsActionColumn
          v-bind="{ bike }"
        />
      </template>
    </DataTable>
  </div>
</template>
<script type="text/javascript">
import DataTable from '@/components/DataTable'
import HasManifestId from '@/mixins/HasManifestId'
import ManifestDriverJobsActionColumn from '@/components/manifests/ManifestDriverJobsActionColumn'
import ManifestJobNotesButton from '@/components/manifests/ManifestJobNotesButton'
import debounce from 'lodash.debounce'

export default {
  name: 'ManifestDriverJobs',

  mixins: [ HasManifestId ],

  props: {
    manifest: {
      type: Object,
      required: true
    }
  },

  components: {
    DataTable,
    ManifestDriverJobsActionColumn,
    ManifestJobNotesButton
  },

  mounted () {
    if (window.Echo) {
      window.Echo.private(`manifests.${this.manifestId}`)
        .listen('ManifestUpdated', () => {
          debounce(() => {
            if (this.$refs.dataTable) {
              this.$refs.dataTable.getData()              
            }
          }, 1000)
        })
      window.Echo.private(`${this.manifestId}.routes`)
        .listen('RouteCalculated', () => {
            if (this.$refs.dataTable) {
              this.$refs.dataTable.getData()              
            }
        })
      window.Echo.private(`booking-bikes.${this.manifestId}`)
        .listen('BookingBikeUpdated', () => {
          if (this.$refs.dataTable) {
            this.$refs.dataTable.getData()
          }
        })
        .listen('BookingBikeCreated', () => {
          if (this.$refs.dataTable) {
            this.$refs.dataTable.getData()
          }
        })
    }
  },

  data () {
    return {
      loading: false,
      dialog: false,
      cols: [
        { text: 'Job', value: 'ref' },
        { text: 'Actions', value: 'actions' }
      ]
    }
  },

  methods: {
    startManifest () {
      this.loading = true
      
      this.$api.persist('post', {
        path: `manifests/${this.manifestId}/driver-start`
      })
        .then(() => {
          this.$emit('reload:manifest')
        })
        .catch(() => {
          // this.$refs.formErrors.setErrors(error)
          // this.$refs.errorBar.open()
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>
