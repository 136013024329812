<template>
  <div>
    <p class="green--text font-weight-medium text-body-2" v-if="!(bike.available_workshop_statuses.includes('STATUS_AWAITING_TRIAGE') && !isReturn && availableStatuses.length === 0) && !(availableStatuses.includes('STATUS_DISPATCHED') || availableStatuses.includes('STATUS_EN_ROUTE')) && !(availableStatuses.includes('STATUS_RETURNED') || availableStatuses.includes('STATUS_COLLECTED')) && !(availableStatuses.includes('STATUS_RETURN_FAILED') || availableStatuses.includes('STATUS_COLLECTION_FAILED'))">
      Complete
      <v-icon small color="green">mdi-check-circle-outline</v-icon>
    </p>
    <v-btn
      v-if="bike.available_workshop_statuses.includes('STATUS_AWAITING_TRIAGE') && !isReturn && availableStatuses.length === 0 && !['JOB_TYPE_FLEET_ONSITE', 'JOB_TYPE_FLEET_ONSITE_COMPLAINT', 'JOB_TYPE_DOORSTEP', 'JOB_TYPE_COMPLAINT'].includes(bike.job_type)"
      color="primary"
      depressed
      small
      @click="arrivalDialog = true;"
    >
      Arrived
    </v-btn>
    <v-btn
      v-if="availableStatuses.includes('STATUS_DISPATCHED') || availableStatuses.includes('STATUS_EN_ROUTE')"
      class="mr-2"
      color="primary"
      depressed
      small
      @click="actionDialog = true;"
    >
      {{ isReturn ? 'Dispatched' : 'En-Route' }}
    </v-btn>
    <v-btn
      v-if="availableStatuses.includes('STATUS_RETURNED') || availableStatuses.includes('STATUS_COLLECTED')"
      class="mr-2"
      color="green"
      dark
      depressed
      small
      @click="successDialog = true"
    >
      {{ isReturn ? (['JOB_TYPE_FLEET_ONSITE', 'JOB_TYPE_FLEET_ONSITE_COMPLAINT', 'JOB_TYPE_DOORSTEP', 'JOB_TYPE_COMPLAINT'].includes(bike.job_type) ? 'Completed' : 'Returned') : (['JOB_TYPE_FLEET_ONSITE', 'JOB_TYPE_FLEET_ONSITE_COMPLAINT', 'JOB_TYPE_DOORSTEP', 'JOB_TYPE_COMPLAINT'].includes(bike.job_type) ? 'Arrived' : 'Collected') }}
    </v-btn>
    <v-btn
      v-if="availableStatuses.includes('STATUS_RETURN_FAILED') || availableStatuses.includes('STATUS_COLLECTION_FAILED')"
      color="red"
      depressed
      small
      outlined
      @click="actionDialog = true; actionDialogType = 'failure'"
    >
      Failed
    </v-btn>

    <DriverCRActionDialog
      :dialog.sync="actionDialog"
      :bike="bike"
      :collection="!isReturn"
      :failure="actionDialogType === 'failure'"
    />

    <DriverCRSuccessDialog
      :dialog.sync="successDialog"
      :bike="bike"
      :collection="!isReturn"
    />

    <DriverCRArrivedDialog
      :dialog.sync="arrivalDialog"
      :bike="bike"
    />
  </div>
</template>
<script type="text/javascript">
import HasBike from '@/mixins/HasBike'
import DriverCRActionDialog from '@/components/manifests/DriverCRActionDialog'
import DriverCRSuccessDialog from '@/components/manifests/DriverCRSuccessDialog'
import DriverCRArrivedDialog from '@/components/manifests/DriverCRArrivedDialog'

export default {
  name: 'ManifestDriverJobsActionColumn',

  mixins: [ HasBike ],

  components: {
    DriverCRActionDialog,
    DriverCRSuccessDialog,
    DriverCRArrivedDialog
  },

  data () {
    return {
      successDialog: false,
      actionDialog: false,
      arrivalDialog: false,
      actionDialogType: null
    }
  },

  computed: {
    isReturn () {
      return this.bike.booking_bike_manifest.type === 'TYPE_RETURN'
    },

    availableStatuses () {
      if (this.isReturn) {
        return this.bike.available_return_statuses
      }
      return this.bike.available_collection_statuses
    }
  }
}
</script>
