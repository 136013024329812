<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span v-if="failure" class="headline">{{ collection ? (['JOB_TYPE_FLEET_ONSITE', 'JOB_TYPE_FLEET_ONSITE_COMPLAINT', 'JOB_TYPE_DOORSTEP', 'JOB_TYPE_COMPLAINT'].includes(bike.job_type) ? 'Arrival' : 'Collection') : 'Return' }} Failed</span>
          <span v-else class="headline">{{ collection ? 'En-Route' : 'Dispatched' }}</span>

          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-alert v-if="failure" type="info" text outlined>
            <span v-if="collection">Please be sure to take a picture of the customers door / entrance</span>
            <span v-else>Please be sure to take a picture of the customers bike next to their door / entrance</span>
          </v-alert>
          <v-form
            ref="form"
            v-model="validForm"
          >
            <v-row class="mb-4">
              <v-col
                cols="12"
                sm="4"
              >
                <FileUpload
                  v-model="files"
                  multiple
                />
              </v-col>
            </v-row>
          </v-form>
          <FormErrors ref="formErrors" />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" x-small text @click="closeDialog">
            Close
          </v-btn>
          <v-btn color="primary" text small :loading="loading" @click="updateBike">
            {{ failure ? (collection ? (['JOB_TYPE_FLEET_ONSITE', 'JOB_TYPE_FLEET_ONSITE_COMPLAINT', 'JOB_TYPE_DOORSTEP', 'JOB_TYPE_COMPLAINT'].includes(bike.job_type) ? 'Arrival Failed' : 'Collection Failed') : 'Return Failed') : (collection ? 'En-Route' : 'Dispatched') }}
            <v-icon small right>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      Bike marked {{ collection ? 'en-route' : 'dispatched' }} successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue updating the bikes status.
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'
import HasBike from '@/mixins/HasBike'
import FormErrors from '@/components/FormErrors'
import FileUpload from '@/components/file-upload/FileUpload'
import SnackBar from '@/components/SnackBar'

export default {
  name: 'DriverCRActionDialog',

  props: {
    collection: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    failure: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  mixins: [ HasDialog, HasForm, HasBike ],

  components: {
    FormErrors,
    FileUpload,
    SnackBar
  },

  data () {
    return {
      files: [],
      loading: false,
      error: {}
    }
  },

  computed: {
    updateStatusObject () {
      if (this.failure) {
        if (this.collection) {
          return { collection_status: 'STATUS_COLLECTION_FAILED' }
        }
        return { return_status: 'STATUS_RETURN_FAILED' }
      }
      if (this.collection) {
        return { collection_status: 'STATUS_EN_ROUTE' }
      }
      return { return_status: 'STATUS_DISPATCHED' }
    }
  },

  methods: {
    updateBike () {
      this.loading = true
      this.$api.persist('put', {
        path: `bookings/${this.bike.booking_id}/bikes/${this.bike.id}`,
        object: {
          ...this.updateStatusObject,
          ...{
            job_number: this.bike.ref
          }
        }
      })
        .then(() => {
          this.$refs.successBar.open()
          this.error = {}
          this.closeDialog()
        })
        .catch(error => {
          this.$refs.formErrors.setErrors(error)
          this.$refs.errorBar.open()
          this.error = error
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>
