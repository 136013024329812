<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="headline">Arrived</span>

          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-form
            ref="form"
            v-model="validForm"
          >
            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  v-model="form.location_prefix"
                  :items="availableLocationTypes"
                  label="Location Type"
                  item-text="label"
                  item-value="value"
                  outlined
                  required
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="form.location"
                  label="Location"
                  type="number"
                  step="1"
                  min="0"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-form>
          <FormErrors ref="formErrors" />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text x-small @click="closeDialog">Close</v-btn>
          <v-btn color="primary" text small :loading="loading" @click="updateBike">
            Arrived
            <v-icon small right>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      Bike marked arrived successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue updating the bikes status.
    </SnackBar>
  </div>
</template>

<script>
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'
import HasBike from '@/mixins/HasBike'
import FormErrors from '@/components/FormErrors'
// import FileUpload from '@/components/file-upload/FileUpload'
import SnackBar from '@/components/SnackBar'

export default {
  name: 'DriverCRArrivedDialog',

  mixins: [ HasDialog, HasForm, HasBike ],

  components: {
    FormErrors,
    // FileUpload,
    SnackBar
  },

  data () {
    return {
      loading: false,
      form: {},
      locationTypes: {}
    }
  },

  mounted () {
    this.getLocationTypes()
  },

  computed: {
    availableLocationTypes () {
      var types = []
      Object.keys(this.locationTypes).forEach(key => {
        types.push({
          label: this.locationTypes[key],
          value: key
        })
      })
      return types
    }
  },

  methods: {
    getLocationTypes () {
      this.$api.get({
        path: 'bike-location-types'
      })
        .then(data => {
          this.locationTypes = data
        })
    },

    updateBike () {
      this.loading = true

      this.$api.persist('put', {
        path: `bookings/${this.bike.booking_id}/bikes/${this.bike.id}`,
        object: {
          ...this.form,
          ...{
            workshop_status: 'STATUS_AWAITING_TRIAGE'
          }
        }
      })
        .then(() => {
          this.$refs.successBar.open()
          this.closeDialog()
        })
        .catch(error => {
          this.$refs.formErrors.setErrors(error)
          this.$refs.errorBar.open()
          this.error = error
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>
