<template>
  <div>
    <v-container fluid>
      <InnerPageBackHeader @back="$router.go(-1)">
        <template v-slot:right>
          <div class="d-flex justify-content-between align-items-center w-full">
            <div :class="{ 'pt-2': !manifest.started_at }">
              <p class="mb-0 pb-0 pt-0 mt-0" style="line-height: 0.1;"><small><strong>Driver:</strong> {{ manifest.driver.name }}</small></p>
              <p class="mb-0 mt-0 pt-0 pb-0"><small><strong>Vehicle:</strong> {{ manifest.vehicle.name }}</small></p>
              <p v-if="manifest.started_at" class="mb-0 mt-0 pt-0" style="line-height: 0.1;"><small><strong>Started At:</strong> {{ $momentDateTimeHuman(manifest.started_at, true) }}</small></p>
            </div>
            <v-btn
              outlined
              dark
              small
              depressed
              color="primary"
              @click="getManifest"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </div>
        </template>
      </InnerPageBackHeader>
      <v-btn
        v-if="manifest.published_at"
        :href="manifest.pdf_url"
        class="mt-3 mb-3"
        color="success"
        block
      >
        Print Manifest
      </v-btn>
      <ManifestDriverJobs
        v-bind="{ manifestId, manifest }"
        @reload:manifest="getManifest"
      />
    </v-container>
  </div>
</template>
<script type="text/javascript">
import ManifestDriverJobs from '@/components/manifests/ManifestDriverJobs'
import HasManifestId from '@/mixins/HasManifestId'
import InnerPageBackHeader from '@/components/InnerPageBackHeader'

export default {
  name: 'ManifestDriverView',

  components: {
    ManifestDriverJobs,
    InnerPageBackHeader
  },

  mixins: [
    HasManifestId
  ],

  data () {
    return {
      tabValue: null,
      loading: false,
      error: {},
      manifest: {
        driver: {},
        vehicle: {}
      }
    }
  },

  mounted () {
    this.getManifest()
  },

  methods: {
    getManifest () {
      this.loading = true
      this.$api.get({
        path: `manifests/${this.manifestId}`,
        params: {
          with: ['driver', 'vehicle']
        }
      })
        .then(({ data: manifest }) => {
          this.manifest = manifest
        })
        .catch(error => {
          this.error = error
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>
